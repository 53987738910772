import React, {useState, useEffect} from 'react';
import style from './NavigationBar.module.scss';
import classnames from 'classnames';
import map from 'lodash/map';
import NavigationLink from "../../components/NavigationBar/NavigationLink/NavigationLink";
import MainMenu from "../../components/NavigationBar/MainMenu/MainMenu";
import menuImage from '../../media/menu.svg';

const ACTIVATE_SHRINK_SCROLL_REMS = 3;
const DEACTIVATE_SHRINK_SCROLL_REMS = 2;
const FOCUSED_SECTION_SCREEN_DISTANCE_FACTOR = 0.33;
const FOCUSED_BOTTOM_SECTION_DISTANCE_FACTOR = 0.33;

function NavigationBar({sectionsDef, ...rest}) {
    const [isShrunk, setShrunk] = useState(false);
    const [focusedSection, setFocusedSection] = useState(-1);
    const [menuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        const remPixelSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        let isNavBarShrunk = false;
        let lastFocusedSection = -1;
        const pageScrolled = () => {
            const pageYOffset = window.pageYOffset;
            const screenHeight = document.documentElement.clientHeight;

            if (!isNavBarShrunk && pageYOffset >= remPixelSize * ACTIVATE_SHRINK_SCROLL_REMS) {
                setShrunk(true);
                isNavBarShrunk = true;
            } else if (isNavBarShrunk && pageYOffset <= remPixelSize * DEACTIVATE_SHRINK_SCROLL_REMS) {
                setShrunk(false);
                isNavBarShrunk = false;
            }

            const focusedSectionTopThresholdHeight = screenHeight * FOCUSED_SECTION_SCREEN_DISTANCE_FACTOR;
            let curFocusedSection = -1;
            for (let sectionInd = sectionsDef.length - 1; sectionInd >= 0 && curFocusedSection === -1; sectionInd--) {
                const sectionBoundingRect = sectionsDef[sectionInd].sectionRef.current.getBoundingClientRect();
                if (
                    sectionBoundingRect.top <= focusedSectionTopThresholdHeight || (
                        sectionInd === sectionsDef.length - 1 &&
                        sectionBoundingRect.bottom <= screenHeight + (sectionBoundingRect.height * FOCUSED_BOTTOM_SECTION_DISTANCE_FACTOR)
                    )
                ) {
                    curFocusedSection = sectionInd;
                }
            }
            if (curFocusedSection !== lastFocusedSection) {
                setFocusedSection(curFocusedSection);
                lastFocusedSection = curFocusedSection;
            }
        };

        pageScrolled();
        window.addEventListener( 'scroll', pageScrolled, false);
        return () => {
            window.removeEventListener( 'scroll', pageScrolled);
        };
    }, [sectionsDef]);

    const menuButtonClicked = () => {
        setMenuVisible(!menuVisible);
    };

    const closeMenu = () => {
        setMenuVisible(false);
    };

    return (
        <nav
            className={classnames(
                style.NavigationBar,
                {[style.Shrunk]: isShrunk}
            )}
            {...rest}
        >
            <h1 className={style.NameLabel}>
                Lev Pickovsky
            </h1>
            <ul className={style.NavigationLinksContainer}>
                {map(sectionsDef, ({title, sectionAnchorID}, index) =>
                    <li key={index}>
                        <NavigationLink
                            caption={title}
                            sectionAnchorID={sectionAnchorID}
                            isFocused={index === focusedSection}
                        />
                    </li>
                )}
            </ul>
            <button className={style.MenuButton} onClick={menuButtonClicked}>
                <img className={style.MenuButtonImage} src={menuImage} alt='Menu' />
            </button>
            <MainMenu
                visible={menuVisible}
                navigationBarShrunk={isShrunk}
                onClose={closeMenu}
            >
                <ul className={style.MenuNavigationLinksContainer}>
                    {map(sectionsDef, ({title, sectionAnchorID}, index) =>
                        <li key={index}>
                            <NavigationLink
                                caption={title}
                                sectionAnchorID={sectionAnchorID}
                                isFocused={index === focusedSection}
                                onClick={closeMenu}
                            />
                        </li>
                    )}
                </ul>
            </MainMenu>
        </nav>
    );
}

export default NavigationBar;
