import React, {useEffect} from 'react';
import classnames from 'classnames';
import style from './OverlayModal.module.scss';

function OverlayModal({
    isVisible,
    onClose,
    children
}) {
    useEffect(() => {
        if (isVisible) {
            const escapeKeyDown = (e) => {
                if (e.keyCode === 27) {
                    onClose();
                }
            };
            document.addEventListener("keydown", escapeKeyDown, false);
            return () => {
                document.removeEventListener("keydown", escapeKeyDown, false);
            };
        }
    }, [isVisible, onClose]);

    return (
        <div
            className={classnames(
                style.Overlay, {
                    [style.Visible]: isVisible
                }
            )}
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}
        >
            <div
                className={style.ContentContainer}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
}

export default OverlayModal;
