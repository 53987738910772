import React from 'react';
import style from './NavigationLink.module.scss';
import classnames from 'classnames';

function NavigationLink({caption, sectionAnchorID, isFocused, ...rest}) {
    return (
        <a
            className={classnames(
                style.NavigationLink,
                {[style.Focused]: isFocused}
            )}
            href={`#${sectionAnchorID}`}
            {...rest}
        >
            {caption}
        </a>
    );
}

export default NavigationLink;
