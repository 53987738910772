import React from 'react';
import style from './MainMenu.module.scss';
import classnames from 'classnames';

function MainMenu({
    visible,
    navigationBarShrunk,
    onClose,
    children,
    ...rest
}) {
    return (
        <div
            className={classnames(style.MainMenu, {
                [style.Visible]: visible,
                [style.ShrunkNavigationBar]: navigationBarShrunk
            })}
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}
            {...rest}
        >
            <div
                className={style.MenuContainer}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
}

export default MainMenu;
