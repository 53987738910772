import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import 'core-js';
import smoothscrollPolyfill from 'smoothscroll-polyfill';
import 'smoothscroll-anchor-polyfill';
smoothscrollPolyfill.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
