import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import style from './ProjectModalView.module.scss';
import OverlayModal from "../../OverlayModal/OverlayModal";
import ImageCarousel from '../../ImageCarousel/ImageCarousel';

function ProjectModalView({
    projectID,
    title,
    description,
    images,
    onClose,
    isVisible,
    ...rest
}) {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
    }, []);

    const shouldDisplayImages = !!(images && images.length);
    const [viewedImageIndex, setViewedImageIndex] = useState(0);
    const onScrollViewedImageLeft = () => {
        if (viewedImageIndex > 0) {
            setViewedImageIndex(viewedImageIndex - 1);
        }
    };
    const onScrollViewedImageRight = () => {
        if (viewedImageIndex < images.length - 1) {
            setViewedImageIndex(viewedImageIndex + 1);
        }
    };

    return (
        <OverlayModal
            isVisible={mounted && isVisible}
            onClose={() => onClose(projectID)}
        >
            <article
                className={classnames(
                    style.ProjectModalView, {
                        [style.ProjectModalViewWithImages]: shouldDisplayImages
                    }
                )}
                {...rest}
            >
                <h3 className={style.Title}>{title}</h3>
                <p className={style.Description}>{description}</p>
                {shouldDisplayImages && (
                    <div className={style.ImageCarouselContainer}>
                        <ImageCarousel
                            images={images}
                            description='Project Visualization'
                            viewedImageIndex={viewedImageIndex}
                            onScrollViewedImageLeft={onScrollViewedImageLeft}
                            onScrollViewedImageRight={onScrollViewedImageRight}
                        />
                    </div>
                )}
                {shouldDisplayImages && (
                    <span className={style.ViewedImageInfo}>
                        {`${viewedImageIndex + 1} / ${images.length}`}
                    </span>
                )}
                <button
                    onClick={() => onClose(projectID)}
                    className={style.CloseButton}
                >
                    Close
                </button>
            </article>
        </OverlayModal>
    );
}

export default ProjectModalView;
