import projectNNProcessingLibImage1 from "../../media/projects/project_nn_processing_lib_1.png";
import projectNNProcessingLibImage2 from "../../media/projects/project_nn_processing_lib_2.png";
import projectNNProcessingLibImage3 from "../../media/projects/project_nn_processing_lib_3.png";
import projectFileBackupImage from "../../media/projects/project_file_backup.png";
import projectNotesWebAppImage1 from "../../media/projects/project_notes_web_app_1.jpg";
import projectNotesWebAppImage2 from "../../media/projects/project_notes_web_app_2.jpg";
import projectNotesWebAppImage3 from "../../media/projects/project_notes_web_app_3.jpg";
import projectPersonalProfileWebsiteImage1 from "../../media/projects/project_personal_profile_website_1.png";
import projectPersonalProfileWebsiteImage2 from "../../media/projects/project_personal_profile_website_2.png";
import projectRayTracerImage1 from "../../media/projects/project_ray_tracer_1.png";
import projectRayTracerImage2 from "../../media/projects/project_ray_tracer_2.png";
import projectElectroboardImage1 from "../../media/projects/project_electroboard_1.jpg";
import projectElectroboardImage2 from "../../media/projects/project_electroboard_2.jpg";
import projectElectroboardImage3 from "../../media/projects/project_electroboard_3.jpg";
import projectRoboticGrabberArmImage from "../../media/projects/project_robotic_grabber_arm.jpg";
import projectLightDimmerImage1 from "../../media/projects/project_light_dimmer_1.jpg";
import projectLightDimmerImage2 from "../../media/projects/project_light_dimmer_2.jpg";
import projectLightDimmerImage3 from "../../media/projects/project_light_dimmer_3.jpg";
import projectLinearPowerSupplyImage from "../../media/projects/project_linear_power_supply.jpg";
import projectMiscElectronicsImage1 from "../../media/projects/project_misc_electronics_1.jpg";
import projectMiscElectronicsImage2 from "../../media/projects/project_misc_electronics_2.jpg";
import projectToyJointsImage1 from "../../media/projects/project_toy_joints_1.jpg";
import projectToyJointsImage2 from "../../media/projects/project_toy_joints_2.jpg";
import projectBalconyShadeCanopyImage1 from "../../media/projects/project_balcony_shade_canopy_1.jpg";
import projectBalconyShadeCanopyImage2 from "../../media/projects/project_balcony_shade_canopy_2.jpg";
import projectBalconyShadeCanopyImage3 from "../../media/projects/project_balcony_shade_canopy_3.jpg";
import projectBalconyShadeCanopyImage4 from "../../media/projects/project_balcony_shade_canopy_4.jpg";
import projectCellphoneStandImage1 from "../../media/projects/project_cellphone_stand_1.jpg";
import projectCellphoneStandImage2 from "../../media/projects/project_cellphone_stand_2.jpg";
import projectCakeTrayImage from "../../media/projects/project_cake_tray.jpg";
import projectFilamentDryBoxImage from "../../media/projects/project_filament_dry_box.jpg";
import projectSolderingBaseImage1 from "../../media/projects/project_soldering_base_1.jpg";
import projectSolderingBaseImage2 from "../../media/projects/project_soldering_base_2.jpg";
import projectBlindLabyrinthMazeImage1 from "../../media/projects/project_blind_labyrinth_maze_1.jpg";
import projectBlindLabyrinthMazeImage2 from "../../media/projects/project_blind_labyrinth_maze_2.jpg";
import projectBlindLabyrinthMazeImage3 from "../../media/projects/project_blind_labyrinth_maze_3.jpg";
import projectBabyMonitorCameraMountImage from "../../media/projects/project_baby_monitor_camera_mount.jpg";
import projectWaterHoseAdaptorImage from "../../media/projects/project_water_hose_adaptor.jpg";
import projectBathtubDrainStopperImage from "../../media/projects/project_bathtub_drain_stopper.jpg";

const compactWhitespaces = (text) => text
    .replaceAll(/\s+/g, ' ')
    .replaceAll(/^\s+|\s+$/g, '');

const processAllProjectsText = (items) => items.map((item) => Object.assign({}, item, {
    description: compactWhitespaces(item.description)
}));

export default processAllProjectsText([
    {
        projectID: 'nn-processing-lib',
        title: 'A GPU-utilizing Artificial Neural Network processing library written in Scala',
        description: `
            This Neural Network processing library, which I've implemented in Scala, allows generically
            defining complex recursive network structures (though I'd like to implement additional layer
            types, e.g. Convolutional) and training them using Gradient Descent based on the Backpropagation
            Through Structure technique.
            This makes creating and training arbitrary network structures, such as LSTMs, possible using
            a generic API without hard-coding specific layer types.
            I've also created infrastructure for generic activation function definition via a custom math
            expression DSL (compiled to Scala/CUDA code at runtime).
            Regularization schemes, such as Dropout, are supported as well.
            Processing is done using an abstract Compute System software layer which I've implemented 2 ways:
            a simple CPU-based implementation (cache-aware for matrix-matrix multiplication) and a GPU-based
            implementation using NVIDIA CUDA (utilizing JCuda from jcuda.org for the native bindings) allowing
            for much faster training of larger and deeper networks.
        `,
        tileImage: projectNNProcessingLibImage1,
        images: [
            projectNNProcessingLibImage1,
            projectNNProcessingLibImage2,
            projectNNProcessingLibImage3
        ]
    },
    {
        projectID: 'file-backup',
        title: 'A file backup application written in Python' ,
        description: `
            I decided to roll my own simple implementation of a file backup program not just for the experience but
            also due to not being really satisfied with the commercial solution I'd been using in terms of
            functionality (e.g. lack of complex inclusion/exclusion rules and not marking deleted files as such).
            My current implementation stores file versions in an AWS S3 bucket using Infrequent Access storage
            (other implementations are possible thanks to an abstraction layer), uploads via concurrent multipart
            upload, and is built using Python.
            The application's backup operation lists the files and directories on both ends and compares their
            modification dates and sizes to decide on the appropriate actions.
        `,
        tileImage: projectFileBackupImage,
        images: [
            projectFileBackupImage
        ]
    },
    {
        projectID: 'notes-web-app',
        title: 'A bare-bones notes web app',
        description: `
            I built this extremely simple notes SPA-type web app some years ago as a very cheap and useful,
            albeit primitive, way of storing passwords and general useful information.
            I used bare-bones HTML, CSS and JavaScript, and a stack of AWS services: S3 for data storage
            (including backups), Cognito for user authentication management, and S3 + CloudFront + Route53 + ACM
            for static web hosting, a custom domain and HTTPS support.
            The app turned out useful enough for me and my wife to use till this day.
        `,
        tileImage: projectNotesWebAppImage1,
        images: [
            projectNotesWebAppImage1,
            projectNotesWebAppImage2,
            projectNotesWebAppImage3
        ]
    },
    {
        projectID: 'pro-profile-site',
        title: 'This personal profile web page',
        description: `
            I implemented this simple personal profile web page using React (+ some extras such as polyfills)
            and built and bundled it with the setup created by Create React App for the convenience of having things
            like Webpack, Babel, SASS and CSS Modules automatically configured.
            The page provides layouts for both large and mobile device screens.
            I opted for implementing my own UI components for the additional learning experience (rather than using a
            framework like Bootstrap).
            The introductory video was edited using the powerful FFmpeg CLI tool, and encoded using AVC video
            and AAC audio in an MP4 container for wide compatibility and with parameters optimized for a smaller
            file size.
            Image assets were also resized and compressed for smaller file sizes.
            Similarly to my notes web app, AWS S3 + CloudFront + Route53 + ACM are used for static web hosting,
            a custom domain, HTTPS support, and CDN functionality, and I've also used AWS SES and Lambda for
            receiving and forwarding email on my custom domain.
        `,
        tileImage: projectPersonalProfileWebsiteImage1,
        images: [
            projectPersonalProfileWebsiteImage1,
            projectPersonalProfileWebsiteImage2
        ]
    },
    {
        projectID: 'ray-tracer',
        title: 'A Ray Tracer written in C#',
        description: `
            This is a little Ray Tracer project that I built some time ago in C# just to try out this simple yet
            very interesting concept in practice.
            A 3D scene is set up (just using code in this case, no GUI), including object positions and properties
            such as color and reflectiveness, light sources (spot and ambient), and the camera angle, and then
            a virtual ray of light is "shot" from the camera through every pixel of a virtual screen while tracing
            all of its encounters with objects and light sources and backward-calculating its final color (while also
            averaging with nearby pixels, or shooting several offset rays for every pixel, for anti-aliasing).
        `,
        tileImage: projectRayTracerImage1,
        images: [
            projectRayTracerImage1,
            projectRayTracerImage2
        ]
    },
    {
        projectID: 'electronics-projects',
        title: 'Electronics projects',
        description: `
            These are some fun electronics projects that I've built:
            • An Arduino-based electronic "busy board" that I made for my son, enclosed by a custom 3D-printed shell.
            • A robotic grabber arm that I built out of an old PC CD drive, an Arduino board, custom electronics,
            an infrared remote control and sensor, and custom 3D-printed parts.
            • A WiFi-controlled "trailing edge" light dimmer, based on custom electronics and an ESP8266
            microcontroller board.
            • A simple linear power supply for my electronics lab.
            • Miscellaneous projects and tinkering with things such as IoT, Software Defined Radio and robotics.
        `,
        tileImage: projectElectroboardImage1,
        images: [
            projectElectroboardImage1,
            projectElectroboardImage2,
            projectElectroboardImage3,
            projectRoboticGrabberArmImage,
            projectLightDimmerImage1,
            projectLightDimmerImage2,
            projectLightDimmerImage3,
            projectLinearPowerSupplyImage,
            projectMiscElectronicsImage1,
            projectMiscElectronicsImage2
        ]
    },
    {
        projectID: '3d-printing-projects',
        title: '3D-printing projects',
        description: `
            These are some of my 3D-printing projects:
            • Rotary limb joints that I make for children's toys that my wife knits.
            • Custom-fit parts for hanging a shade canopy above our balcony.
            • An adjustable cellphone stand.
            • A round tray for shaping layers of cake.
            • A "dry box" for 3D-printing filament.
            • A table-attached base for screwed-in soldering helping tools.
            • A "blind labyrinth" maze for my wife's birthday.
            • A baby monitor camera clip mount, custom-fit for a cabinet of ours.
            • A custom water hose adaptor.
            • A bathtub drain stopper.
        `,
        tileImage: projectToyJointsImage1,
        images: [
            projectToyJointsImage1,
            projectToyJointsImage2,
            projectBalconyShadeCanopyImage1,
            projectBalconyShadeCanopyImage2,
            projectBalconyShadeCanopyImage3,
            projectBalconyShadeCanopyImage4,
            projectCellphoneStandImage1,
            projectCellphoneStandImage2,
            projectCakeTrayImage,
            projectFilamentDryBoxImage,
            projectSolderingBaseImage1,
            projectSolderingBaseImage2,
            projectBlindLabyrinthMazeImage1,
            projectBlindLabyrinthMazeImage2,
            projectBlindLabyrinthMazeImage3,
            projectBabyMonitorCameraMountImage,
            projectWaterHoseAdaptorImage,
            projectBathtubDrainStopperImage
        ]
    },
]);
