import React, {useRef, useState} from 'react';
import classnames from 'classnames';
import style from './Sections.module.scss';
import Section from "../../components/Sections/Section/Section";
import ProjectTile from "../../components/Sections/ProjectTile/ProjectTile";
import ProjectModalView from "../../components/Sections/ProjectModalView/ProjectModalView";
import avatarImage from '../../media/avatar.jpg';
// import curlyBracketLeftImage from '../../media/curly_bracket_left.svg';
// import curlyBracketRightImage from '../../media/curly_bracket_right.svg';
import codeBackgroundImage from '../../media/code_background.jpg';
import introductoryClip from '../../media/introductory_clip.mp4';

import OFF_WORK_PROJECTS from './OFF_WORK_PROJECTS';

function Sections({sectionsDef, ...rest}) {
    for (const sectionDef of sectionsDef) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        sectionDef.sectionRef = useRef();
    }

    const [viewedOffWorkProject, setViewedOffWorkProject] = useState(null);
    const [everViewedOffWorkProjects, setEverViewedOffWorkProject] = useState({});
    const onOffWorkProjectOpen = (projectID) => {
        setViewedOffWorkProject(projectID);
        if (!everViewedOffWorkProjects[projectID]) {
            setEverViewedOffWorkProject(Object.assign({}, everViewedOffWorkProjects, {
                [projectID]: true
            }));
        }
    };
    const onOffWorkProjectClose = () => {
        setViewedOffWorkProject(null);
    };

    return (
        <main className={style.Sections} {...rest}>
            <Section backdropImage={codeBackgroundImage} id={style.MainTitleSection}>
                <div className={style.MainTitleContainer}>
                    <div className={style.MainTitle}>
                        {/*<img className={style.BlockBracket} src={curlyBracketLeftImage} alt='Left Curly Bracket' />*/}
                        <div className={style.AvatarAndTitleContainer}>
                            <img className={style.AvatarImage} src={avatarImage} alt='Avatar' />
                            <div className={style.TitleContainer}>
                                <h1 className={style.PreTitle}>
                                    Hi, I'm
                                </h1>
                                <h1 className={style.Title}>
                                    Lev Pickovsky
                                </h1>
                            </div>
                        </div>
                        {/*<img className={style.BlockBracket} src={curlyBracketRightImage} alt='Right Curly Bracket' />*/}
                    </div>
                </div>
            </Section>
            <Section title='INTRODUCTION' anchorID='section-introduction' sectionRef={sectionsDef[0].sectionRef}  id={style.IntroductionSection}>
                <div className={classnames(style.ColumnarSubSectionsContainer, style.MinLayoutReverse)}>
                    <div className={style.ColumnarSubSection}>
                        <h3>About Me</h3>
                        <p>
                            I'm a software engineer with extensive experience in the industry and a science and technology
                            geek with a relentless craving for knowledge. For a brief introduction to my background, you can
                            check out the introductory video. The rest of this page provides more details about me, my work
                            and my interests in my professional and personal life.
                        </p>
                        <p>
                            Thank you for your interest and happy viewing <span className={style.Icon} role='img' aria-label='Slightly Smiling Face'>🙂</span>
                        </p>
                    </div>
                    <div className={style.ColumnarSubSection}>
                        <div className={style.IntroductoryClipContainer}>
                            <h3>Introductory Video</h3>
                            <div className={style.IntroductoryClipBorderContainer}>
                                <video controls preload='metadata' className={style.IntroductoryClip}>
                                    <source src={`${introductoryClip}#t=0.001`} type='video/mp4' />
                                </video>
                            </div>
                            <p className={style.IntroductoryClipSizeNote}>
                                * Video download size: ~10MB
                            </p>
                        </div>
                    </div>
                </div>
            </Section>
            <Section title='AT WORK' anchorID='section-at-work' sectionRef={sectionsDef[1].sectionRef}>
                <p>
                    Throughout my career, I've had the pleasure to learn and get hands-on experience with a variety of what the
                    software development industry has to offer, to work in different environments and teams, and to mentor and lead
                    many of my colleagues to successful outcomes.
                    This allowed me to hone my technical, collaborative, leadership and organizational skills.
                    I've mostly worked on designing and developing backend system components, while being involved in the full
                    development lifecycle from requirements gathering through implementation and testing to delivery and maintenance,
                    but I've always been highly involved in system infrastructure as well, fully administering it at times (and I've
                    also done some frontend work).
                    Below are the highlights of my personality, skills and experience.
                </p>
                <div className={style.ColumnarSubSectionsContainer}>
                    <div className={style.ColumnarSubSection}>
                        <h3>Skills & Personal Traits</h3>
                        <ul className={style.BulletList}>
                            <li>
                                I have a broad and diverse spectrum of interests and experience, spanning everything from the
                                hardware and OS-level infrastructure through high level programming languages and frameworks
                                to large scale distributed system architecture. I'm able to design, debug and optimize solutions
                                throughout the different levels of this stack.
                            </li>
                            <li>
                                I'm very autodidactic and I usually acquire and apply new knowledge very quickly.
                            </li>
                            <li>
                                I'm very collaborative and highly experienced in working with different teams and stakeholders
                                as well as providing mentroship to my teammates and leading technical aspects of development.
                            </li>
                            <li>
                                I always work hard to achieve expertise in the things that I work on, learn as much as I can from
                                others and share my own knowledge whenever I can.
                            </li>
                            <li>
                                I'm very open-minded with regard to different ideas, technology and working methodologies.
                                I always bear in mind that complex problems invite a variety of possible solutions.
                            </li>
                            <li>
                                I always strive for clear and effective communication, which I consider to be a foundation of
                                successful teamwork.
                            </li>
                            <li>
                                I'm self-motivated and highly dedicated to my work.
                            </li>
                        </ul>
                    </div>
                    <div className={style.ColumnarSubSection}>
                        <h3>Technical Knowledge & Experience</h3>
                        <ul className={style.BulletList}>
                            <li>
                                A variety of programming languages and runtime environments: statically and dynamically typed,
                                imperative and functional, compiled and interpreted, native and VM execution, multithreaded,
                                event loop and message passing execution models, etc. This includes: Java, C & C++, Ruby, JavaScript,
                                Python, Scala, Elixir and more.
                            </li>
                            <li>
                                Lower-level mechanisms and APIs: process and thread management, concurrency (e.g. mutual exclusion
                                and memory visibility), IPC (e.g. Sockets, Pipes, FIFOs, and memory mapping), and networking (e.g. the
                                network stack, IP routing, DNS, TLS certificates, NAT, and tunneling).
                            </li>
                            <li>
                                Data storage and processing: relational databases (e.g. MySQL), various NoSQL databases (e.g. Cassandra, Elasticsearch, MongoDB,
                                Redis, DynamoDB, and Aerospike), and some big data processing systems (e.g. Hadoop + Hive, S3 and
                                Parquet-based data lakes, Spark on AWS EMR, and Redshift).
                            </li>
                            <li>
                                Process communication: RESTful web APIs, message queues (e.g. RabbitMQ and AWS SQS), event streaming
                                using Kafka, and Pub/Sub.
                            </li>
                            <li>
                                Docker container-based virtualization.
                            </li>
                            <li>
                                Designing large scale and high throughput distributed systems while handling aspects
                                such as load balancing, fault tolerance, automatic scaling, monitoring, etc.
                            </li>
                            <li>
                                Cloud computing using the large array of virtual infrastructure and managed services
                                provided by AWS.
                            </li>
                            <li>
                                Some frontend web development using the React framework.
                            </li>
                        </ul>
                    </div>
                </div>
            </Section>
            <Section title='OFF WORK' anchorID='section-off-work' sectionRef={sectionsDef[2].sectionRef}>
                <p>
                    I've been fascinated with science and technology for as long as I can remember due to my curious and
                    pragmatic nature.
                    I like analyzing and understanding how everything around me works as well as solving problems and puzzles.
                    Following astronomy news and reading articles about electronics, quantum mechanics, medicine, etc. is
                    an everyday part of my life.
                    When I find the time, I love creating both fun and/or useful software projects and physical items.
                    I have a little electronics lab at home, which also houses my 3D printer, and I love learning from the
                    invaluable source of information which is the wonderful international DIY/Maker community.
                    I also love fixing our own household devices as much as my skills permit.
                    Below are some of the little projects that I've worked on just for fun or for their utility.
                </p>
                <ul className={style.OffWorkProjectsContainer}>
                    {OFF_WORK_PROJECTS.map(({
                        projectID,
                        title,
                        description,
                        tileImage,
                        images
                    }) => (
                        <li key={projectID} className={style.ProjectTileContainer}>
                            <div className={style.ProjectTileInnerContainer}>
                                <ProjectTile
                                    projectID={projectID}
                                    title={title}
                                    image={tileImage}
                                    onOpen={onOffWorkProjectOpen}
                                />
                                {(viewedOffWorkProject === projectID || everViewedOffWorkProjects[projectID]) && (
                                    <ProjectModalView
                                        projectID={projectID}
                                        title={title}
                                        description={description}
                                        images={images}
                                        isVisible={viewedOffWorkProject === projectID}
                                        onClose={onOffWorkProjectClose}
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </Section>
            <Section title='CONTACT' anchorID='section-contact' sectionRef={sectionsDef[3].sectionRef}  id={style.ContactSection}>
                <div className={style.CenteredSubSection}>
                    <p>
                        Thank you for taking the time to view my profile page. Please feel free to contact me
                        whichever way you find convenient.
                    </p>
                    <div className={style.ContactLinksContainer}>
                        <a href='https://www.linkedin.com/in/lev-pickovsky' className={style.ContactLink}>
                            <svg className={style.ContactLinkIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path className={style.ContactLinkIconSVGPath} d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                            </svg>
                            <span className={style.ContactText}>LinkedIn</span>
                        </a>
                        <a href='mailto:contact@levpick.info' className={style.ContactLink}>
                            <svg className={style.ContactLinkIcon} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path className={style.ContactLinkIconSVGPath} d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z"/>
                            </svg>
                            <span className={style.ContactText}>contact@levpick.info</span>
                        </a>
                    </div>
                </div>
            </Section>
        </main>
    );
}

export default Sections;
