import React from 'react';
import style from './App.module.scss';
import NavigationBar from './containers/NavigationBar/NavigationBar';
import Sections from './containers/Sections/Sections';

const SECTIONS_DEF = [
    {
        title: 'INTRODUCTION',
        sectionAnchorID: 'section-introduction'
    },
    {
        title: 'AT WORK',
        sectionAnchorID: 'section-at-work'
    },
    {
        title: 'OFF WORK',
        sectionAnchorID: 'section-off-work'
    },
    {
        title: 'CONTACT',
        sectionAnchorID: 'section-contact'
    },
];

function App() {
    return (
        <div className={style.App}>
            <NavigationBar sectionsDef={SECTIONS_DEF} />
            <Sections sectionsDef={SECTIONS_DEF} />
        </div>
    );
}

export default App;
