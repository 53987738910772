import React, {useRef, useState} from 'react';
import classnames from 'classnames';
import map from 'lodash/map';
import style from './ImageCarousel.module.scss';
import scrollArrowLeftImage from '../../media/scroll_arrow_left.svg';
import scrollArrowRightImage from '../../media/scroll_arrow_right.svg';

const TRACK_DRAGGING_SCROLL_MIN_RATIO = 0.33;

function ImageCarousel({
    images,
    description,
    viewedImageIndex,
    onScrollViewedImageLeft,
    onScrollViewedImageRight,
    ...rest
}) {
    const carouselRef = useRef();
    const carouselWidth = carouselRef.current ? carouselRef.current.getBoundingClientRect().width : 0;

    const [trackDragging, setTrackDragging] = useState(null);
    const onTrackPointerDown = (e) => {
        if (e.pointerType !== 'touch' && e.pointerType !== 'pen') return;
        e.target.setPointerCapture(e.pointerId);
        setTrackDragging({
            origin: e.clientX,
            offset: 0
        });
    };
    const onTrackPointerMove = (e) => {
        if (trackDragging) {
            setTrackDragging(Object.assign({}, trackDragging, {
                offset: e.clientX - trackDragging.origin
            }));
        }
    };
    const onTrackPointerUp = (e) => {
        e.target.releasePointerCapture(e.pointerId);
        if (carouselWidth > 0 && trackDragging) {
            if (trackDragging.offset / carouselWidth >= TRACK_DRAGGING_SCROLL_MIN_RATIO) {
                onScrollViewedImageLeft();
            } else if (trackDragging.offset / carouselWidth <= -TRACK_DRAGGING_SCROLL_MIN_RATIO) {
                onScrollViewedImageRight();
            }
        }
        setTrackDragging(null);
    };
    const onTrackPointerCancel = () => {
        setTrackDragging(null);
    };

    let carouselTrackDraggingOffset = 0;
    if (carouselWidth > 0 && trackDragging) {
        carouselTrackDraggingOffset = Math.max(
            Math.min(trackDragging.offset, carouselWidth),
            -carouselWidth
        )
    }

    return (
        <div
            className={style.Carousel}
            ref={carouselRef}
            {...rest}
        >
            <div
                className={classnames(style.CarouselTrack, {
                    [style.CarouselTrackDragging]: !!trackDragging
                })}
                style={{
                    transform: `translateX(-${100 * viewedImageIndex}%) translateX(${carouselTrackDraggingOffset}px)`
                }}
                onPointerDown={onTrackPointerDown}
                onPointerMove={onTrackPointerMove}
                onPointerUp={onTrackPointerUp}
                onPointerCancel={onTrackPointerCancel}
            >
                {map(images, (image, index) =>
                    <div key={index} className={style.CarouselImageContainer}>
                        <img src={image} alt={description} className={style.CarouselImage} />
                    </div>
                )}
            </div>
            <button
                className={classnames(
                    style.ScrollButton,
                    style.ScrollButtonLeft, {
                        [style.HiddenScrollButton]: viewedImageIndex === 0
                    }
                )}
                onClick={onScrollViewedImageLeft}
            >
                <img src={scrollArrowLeftImage} alt='Scroll Left' className={style.ArrowImage} />
            </button>
            <button
                className={classnames(
                    style.ScrollButton,
                    style.ScrollButtonRight, {
                        [style.HiddenScrollButton]: viewedImageIndex === images.length - 1
                    }
                )}
                onClick={onScrollViewedImageRight}
            >
                <img src={scrollArrowRightImage} alt='Scroll Right' className={style.ArrowImage} />
            </button>
        </div>
    );
}

export default ImageCarousel;
