import React from 'react';
import style from './Section.module.scss';
import classnames from 'classnames';

function Section({
    title,
    anchorID,
    sectionRef,
    backdropImage,
    children,
    ...rest
}) {
    return (
        <section
            ref={sectionRef}
            className={classnames(
                style.Section, {
                    [style.WithBackdrop]: !!backdropImage
                }
            )}
            {...rest}
        >
            {anchorID && (
                <a className={style.ScrollAnchor} id={anchorID}>&nbsp;</a>
            )}
            {backdropImage && (
                <div
                    className={style.Backdrop}
                    style={{ backgroundImage: `url(${backdropImage})` }}
                />
            )}
            {title && (
                <div className={style.SectionHeader}>
                    <div className={style.SectionTitleContainer}>
                        <h2 className={style.SectionTitle}>{title}</h2>
                        {/* <h5 className={style.SectionTitle}>{title}</h5> */}
                    </div>
                </div>
            )}
            {children}
        </section>
    );
}

export default Section;
