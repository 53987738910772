import React from 'react';
import style from './ProjectTile.module.scss';

function ProjectTile({
    projectID,
    title,
    image,
    onOpen,
    ...rest
}) {
    return (
        <button
            className={style.ProjectTile}
            onClick={() => onOpen(projectID)}
            {...rest}
        >
            <span className={style.Title}>{title}</span>
            <span
                role='img' aria-label='Project Avatar'
                style={{ backgroundImage: `url(${image})` }}
                className={style.ProjectImageContainer}
            />
        </button>
    );
}

export default ProjectTile;
